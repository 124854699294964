<template>
  <svg
    :height="`${height}px`"
    :width="`${width}px`"
    :class="`stroke-current text-${color}`"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 5.33334V8.00001V5.33334ZM7.99992 8.00001V10.6667V8.00001ZM7.99992 8.00001H10.6666H7.99992ZM7.99992 8.00001H5.33325Z"
    />
    <path
      d="M7.99992 8.00001H5.33325M7.99992 5.33334V8.00001V5.33334ZM7.99992 8.00001V10.6667V8.00001ZM7.99992 8.00001H10.6666H7.99992Z"
      stroke-width="2"
    />
    <path
      d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z"
      stroke-width="2"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "yellow-primary",
    },
    width: {
      type: String,
      default: "16",
    },
    height: {
      type: String,
      default: "16",
    },
  },
};
</script>
