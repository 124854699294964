var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current text-".concat(_vm.color),
    attrs: {
      "viewBox": "0 0 14 10",
      "height": "".concat(_vm.height, "px") || '20px',
      "width": "".concat(_vm.width, "px") || '20px'
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 0H1.2C0.6 0 0 0.500005 0 1.2V8.2C0 8.8 0.5 9.4 1.2 9.4H12C12.6 9.4 13.2 8.9 13.2 8.2V1.2C13.2 0.500005 12.7 0 12 0ZM11.9 0.800003C11.7 1 7.6 5.1 7.4 5.2C7.2 5.4 6.9 5.5 6.6 5.5C6.3 5.5 6 5.4 5.8 5.2C5.7 5.1 1.7 1.1 1.3 0.800003H11.9ZM0.8 8V1.4L4.1 4.7L0.8 8ZM1.3 8.5L4.6 5.2L5.2 5.8C5.6 6.2 6.1 6.4 6.6 6.4C7.1 6.4 7.6 6.2 8 5.8L8.6 5.2L11.9 8.5H1.3ZM12.4 8L9.1 4.7L12.4 1.4V8Z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }