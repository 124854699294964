<template>
  <svg
    :height="`${height}px`"
    :width="`${width}px`"
    :class="`fill-current  text-${color}`"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.875 28.5C49.875 16.6992 40.3008 7.125 28.5 7.125C16.6992 7.125 7.125 16.6992 7.125 28.5C7.125 40.3008 16.6992 49.875 28.5 49.875C40.3008 49.875 49.875 40.3008 49.875 28.5Z"
    />
    <path
      d="M23.5698 40.375C23.2052 40.3743 22.8471 40.2704 22.5306 40.0736C21.818 39.6348 21.375 38.7829 21.375 37.858V19.1421C21.375 18.2147 21.818 17.3654 22.5306 16.9265C22.8546 16.7241 23.2227 16.6201 23.5963 16.6252C23.97 16.6303 24.3355 16.7444 24.6547 16.9555L39.3729 26.5304C39.6796 26.7394 39.9325 27.0297 40.1078 27.374C40.283 27.7183 40.375 28.1053 40.375 28.4988C40.375 28.8922 40.283 29.2793 40.1078 29.6236C39.9325 29.9679 39.6796 30.2581 39.3729 30.4672L24.6524 40.0446C24.3257 40.2592 23.9515 40.3734 23.5698 40.375V40.375Z"
      :fill="innerColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    innerColor: {
      type: String,
      default: "#E8E8E8",
    },
    color: {
      type: String,
      default: "#858585",
    },
    width: {
      type: String,
      default: "57",
    },
    height: {
      type: String,
      default: "57",
    },
  },
};
</script>
