<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.56752 2L6.56541 2.00001C5.61963 2.0028 4.71338 2.37975 4.04461 3.04852C3.37584 3.71729 2.99889 4.62354 2.9961 5.56932H2.99609V5.57143V15.2143L2.9961 15.2163C2.99852 16.0675 3.33773 16.8832 3.93962 17.4851C4.54151 18.0869 5.35715 18.4262 6.20835 18.4286L6.21038 18.4286H6.56745V18.7411C6.56745 20.5409 8.02653 22 9.82638 22H19.7371C21.537 22 22.996 20.5409 22.996 18.7411V8.83035C22.996 7.0305 21.537 5.57142 19.7371 5.57142H19.4174L19.4245 5.22917L19.4247 5.21226C19.4222 4.36106 19.083 3.54542 18.4811 2.94353C17.8793 2.34164 17.0636 2.00243 16.2124 2H16.2104L6.56752 2ZM18.6841 7C18.6869 7.00001 18.6898 7.00001 18.6926 7H19.7371C20.748 7 21.5675 7.81947 21.5675 8.83035V18.7411C21.5675 19.7519 20.748 20.5714 19.7371 20.5714H9.82638C8.8155 20.5714 7.99603 19.7519 7.99603 18.7411V17.7242C7.99607 17.7209 7.99609 17.7176 7.99609 17.7143C7.99609 17.711 7.99607 17.7077 7.99603 17.7044V8.83035C7.99603 7.81947 8.8155 7 9.82638 7H18.6841ZM17.9885 5.57142L17.9961 5.20856C17.9927 4.73764 17.8041 4.28683 17.471 3.95368C17.1362 3.61892 16.6827 3.43017 16.2093 3.42858H6.56866C6.00065 3.43051 5.45643 3.65701 5.05477 4.05868C4.6531 4.46034 4.4266 5.00456 4.42467 5.57257V15.2132C4.42626 15.6866 4.61501 16.1401 4.94977 16.4749C5.28453 16.8097 5.7381 16.9984 6.21149 17H6.56745V8.83035C6.56745 7.0305 8.02653 5.57142 9.82638 5.57142H17.9885Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '25'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
