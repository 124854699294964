var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current  text-".concat(_vm.color),
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11.0804 17.3274C11.0804 17.8353 11.4921 18.247 12 18.247C12.5079 18.247 12.9196 17.8353 12.9196 17.3274L12.9196 5.13986L16.8676 9.08779C17.2267 9.44693 17.809 9.44693 18.1681 9.08779C18.5273 8.72864 18.5273 8.14636 18.1681 7.78722L12.6503 2.26936C12.2911 1.91022 11.7089 1.91022 11.3497 2.26936L5.83186 7.78722C5.47272 8.14636 5.47272 8.72864 5.83186 9.08779C6.191 9.44693 6.77329 9.44693 7.13243 9.08779L11.0804 5.13986L11.0804 17.3274Z"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M2 21.122C2 20.6388 2.39175 20.247 2.875 20.247H21.125C21.6082 20.247 22 20.6388 22 21.122C22 21.6053 21.6082 21.997 21.125 21.997H2.875C2.39175 21.997 2 21.6053 2 21.122Z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }