var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "stroke-current text-".concat(_vm.color),
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7.99992 5.33334V8.00001V5.33334ZM7.99992 8.00001V10.6667V8.00001ZM7.99992 8.00001H10.6666H7.99992ZM7.99992 8.00001H5.33325Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.99992 8.00001H5.33325M7.99992 5.33334V8.00001V5.33334ZM7.99992 8.00001V10.6667V8.00001ZM7.99992 8.00001H10.6666H7.99992Z",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6818 1.33334 7.99992 1.33334C4.31802 1.33334 1.33325 4.31811 1.33325 8.00001C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z",
      "stroke-width": "2"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }