<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.375 7H9C8.60218 7 8.22064 6.84196 7.93934 6.56066C7.65804 6.27936 7.5 5.89782 7.5 5.5V1.125C7.5 1.09185 7.48683 1.06005 7.46339 1.03661C7.43995 1.01317 7.40815 1 7.375 1H4.5C3.96957 1 3.46086 1.21071 3.08579 1.58579C2.71071 1.96086 2.5 2.46957 2.5 3V13C2.5 13.5304 2.71071 14.0391 3.08579 14.4142C3.46086 14.7893 3.96957 15 4.5 15H11.5C12.0304 15 12.5391 14.7893 12.9142 14.4142C13.2893 14.0391 13.5 13.5304 13.5 13V7.125C13.5 7.09185 13.4868 7.06005 13.4634 7.03661C13.4399 7.01317 13.4082 7 13.375 7ZM10.5 12H5.5C5.36739 12 5.24021 11.9473 5.14645 11.8536C5.05268 11.7598 5 11.6326 5 11.5C5 11.3674 5.05268 11.2402 5.14645 11.1464C5.24021 11.0527 5.36739 11 5.5 11H10.5C10.6326 11 10.7598 11.0527 10.8536 11.1464C10.9473 11.2402 11 11.3674 11 11.5C11 11.6326 10.9473 11.7598 10.8536 11.8536C10.7598 11.9473 10.6326 12 10.5 12ZM10.5 9.5H5.5C5.36739 9.5 5.24021 9.44732 5.14645 9.35355C5.05268 9.25979 5 9.13261 5 9C5 8.86739 5.05268 8.74021 5.14645 8.64645C5.24021 8.55268 5.36739 8.5 5.5 8.5H10.5C10.6326 8.5 10.7598 8.55268 10.8536 8.64645C10.9473 8.74021 11 8.86739 11 9C11 9.13261 10.9473 9.25979 10.8536 9.35355C10.7598 9.44732 10.6326 9.5 10.5 9.5Z"
    />
    <path
      d="M13.1006 5.89344L8.60656 1.39937C8.59782 1.39069 8.58671 1.38478 8.57462 1.38239C8.56253 1.38 8.55001 1.38123 8.53862 1.38594C8.52723 1.39065 8.51749 1.39862 8.51062 1.40885C8.50375 1.41908 8.50005 1.43111 8.5 1.44344V5.5C8.5 5.63261 8.55268 5.75978 8.64645 5.85355C8.74021 5.94732 8.86739 6 9 6H13.0566C13.0689 5.99995 13.0809 5.99625 13.0911 5.98938C13.1014 5.98251 13.1093 5.97277 13.1141 5.96138C13.1188 5.94999 13.12 5.93747 13.1176 5.92538C13.1152 5.91329 13.1093 5.90218 13.1006 5.89344V5.89344Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'yellow'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
