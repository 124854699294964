var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current  text-".concat(_vm.color),
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 57 57",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M47.6484 24.9375H32.0625C30.6452 24.9375 29.286 24.3745 28.2839 23.3724C27.2817 22.3702 26.7188 21.011 26.7188 19.5938V4.00781C26.7188 3.88971 26.6718 3.77644 26.5883 3.69293C26.5048 3.60942 26.3915 3.5625 26.2734 3.5625H16.0312C14.1416 3.5625 12.3293 4.31317 10.9931 5.64936C9.65692 6.98556 8.90625 8.79783 8.90625 10.6875V46.3125C8.90625 48.2022 9.65692 50.0144 10.9931 51.3506C12.3293 52.6868 14.1416 53.4375 16.0312 53.4375H40.9688C42.8584 53.4375 44.6707 52.6868 46.0069 51.3506C47.3431 50.0144 48.0938 48.2022 48.0938 46.3125V25.3828C48.0938 25.2647 48.0468 25.1514 47.9633 25.0679C47.8798 24.9844 47.7665 24.9375 47.6484 24.9375ZM37.4062 42.75H19.5938C19.1213 42.75 18.6683 42.5623 18.3342 42.2283C18.0002 41.8942 17.8125 41.4412 17.8125 40.9688C17.8125 40.4963 18.0002 40.0433 18.3342 39.7092C18.6683 39.3752 19.1213 39.1875 19.5938 39.1875H37.4062C37.8787 39.1875 38.3317 39.3752 38.6658 39.7092C38.9998 40.0433 39.1875 40.4963 39.1875 40.9688C39.1875 41.4412 38.9998 41.8942 38.6658 42.2283C38.3317 42.5623 37.8787 42.75 37.4062 42.75ZM37.4062 33.8438H19.5938C19.1213 33.8438 18.6683 33.6561 18.3342 33.322C18.0002 32.988 17.8125 32.5349 17.8125 32.0625C17.8125 31.5901 18.0002 31.137 18.3342 30.803C18.6683 30.4689 19.1213 30.2812 19.5938 30.2812H37.4062C37.8787 30.2812 38.3317 30.4689 38.6658 30.803C38.9998 31.137 39.1875 31.5901 39.1875 32.0625C39.1875 32.5349 38.9998 32.988 38.6658 33.322C38.3317 33.6561 37.8787 33.8438 37.4062 33.8438Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M46.671 20.9954L30.6609 4.98527C30.6297 4.95431 30.5902 4.93326 30.5471 4.92474C30.504 4.91623 30.4594 4.92064 30.4188 4.93741C30.3783 4.95419 30.3435 4.98258 30.3191 5.01903C30.2946 5.05547 30.2814 5.09834 30.2812 5.14224V19.5937C30.2812 20.0662 30.4689 20.5192 30.803 20.8533C31.137 21.1873 31.5901 21.375 32.0625 21.375H46.514C46.5579 21.3748 46.6008 21.3617 46.6372 21.3372C46.6737 21.3127 46.7021 21.278 46.7188 21.2374C46.7356 21.1968 46.74 21.1522 46.7315 21.1092C46.723 21.0661 46.7019 21.0265 46.671 20.9954V20.9954Z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }