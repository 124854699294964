<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6103 3.75315C17.6145 2.74895 19.2426 2.74895 20.2468 3.75315C21.2511 4.75736 21.2511 6.3855 20.2468 7.3897L19.7577 7.87881C19.4648 8.1717 18.99 8.1717 18.6971 7.87881L16.1212 5.30292C15.8283 5.01003 15.8283 4.53515 16.1212 4.24226L16.6103 3.75315Z"
    />
    <path
      d="M14.6732 6.75093C14.3803 6.45803 13.9054 6.45803 13.6125 6.75093L3.21967 17.1438C3.07902 17.2844 3 17.4752 3 17.6741V20.25C3 20.6642 3.33579 21 3.75 21H6.32589C6.5248 21 6.71556 20.921 6.85622 20.7803L17.2491 10.3875C17.542 10.0946 17.542 9.61971 17.2491 9.32681L14.6732 6.75093Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
